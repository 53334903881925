@import '~bootstrap/scss/bootstrap.scss';
@import 'react-slideshow-image/dist/styles.css';

body {
  @extend .text-bg-dark;
  //text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .9);
  //box-shadow: inset 0 0 5rem rgba(0, 0, 0, .9);
  //box-shadow: inset 0 0 15rem rgba(#252d23, .5);
  box-shadow: inset 0 0 10rem rgba(#2f3e2d, .9);
}

.cover-container {
  max-width: 54em;
  @extend .mx-auto;
  //@extend .container;
  //max-width: 60em;


}
#root {
  min-height: 100vh;
}

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

footer {
  a {
    color: white;
  }
}
